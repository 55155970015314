import { WidgetId } from '@wix/members-area-app-definitions';

import type {
  Callback,
  GlobalControllerConfig,
  MemberInfo,
  RouterConfig,
  RoutesConfigurationService,
  SectionData,
  ViewerPublicAPI,
} from '../../types';
import { PROFILE_PAGE_BOB_APP_DEF_ID, PublicApiError } from '../../constants';
import { getMembersLoginWidgets } from '../../services/state';

export class PublicAPI
  implements
    Omit<
      ViewerPublicAPI,
      | 'clearMenus'
      | 'getViewedUser'
      | 'enterPublicProfilePreviewMode'
      | 'leavePublicProfilePreviewMode'
    >
{
  constructor(
    private readonly controllerConfig: GlobalControllerConfig,
    private readonly routeService: RoutesConfigurationService,
  ) {}

  async hasSocialPages(onSuccess?: Callback, onError?: Callback) {
    const routes = await this.getRoutes(onSuccess);

    if (!routes.length) {
      return Promise.resolve(false);
    }

    const hasSocialApps = routes.some((route) => !route.private);

    onSuccess?.(hasSocialApps);

    return Promise.resolve(hasSocialApps);
  }

  async getRoutes(onSuccess?: Callback) {
    if (this.shouldUseNewRoutesService()) {
      const routes = await this.routeService.fetchRouteConfigurations();

      onSuccess?.(routes);
      return routes;
    }

    const { config } = this.controllerConfig;
    const routes = config.routes ?? [];

    onSuccess?.(routes);
    return Promise.resolve(routes);
  }

  async navigateToSection(
    {
      memberId = '',
      tpaInnerRoute = '',
      widgetId,
      appDefinitionId,
    }: SectionData,
    onError?: Callback,
  ) {
    const { location } = this.controllerConfig.wixCodeApi;

    if (!widgetId) {
      const pageURL = await this.getPageUrlByAppDefId(appDefinitionId, onError);
      location.to?.(pageURL);

      return Promise.resolve();
    }

    const route = await this.findRouteByWidgetId(widgetId, onError);
    const membersAreaSectionURL = await this.getMembersAreaSectionURL({
      suffix: route.path,
      memberId,
      tpaInnerRoute,
      onError,
    });

    // special case for following-followers widget, we need to refresh the page to get the correct url in iframe
    if (widgetId === WidgetId.FollowingFollowers) {
      location.to?.(`${location.baseUrl}${membersAreaSectionURL}`);

      return Promise.resolve();
    }

    location.to?.(membersAreaSectionURL);

    return Promise.resolve();
  }

  async navigateToMember(
    { memberId, memberSlug }: MemberInfo,
    onError?: Callback,
  ) {
    if (!memberId) {
      onError?.(PublicApiError.MissingMemberId);
      throw new Error(PublicApiError.MissingMemberId);
    }

    const navigatableHomePage = await this.getNavigatableHomePage(onError);
    const { location } = this.controllerConfig.wixCodeApi;

    if (navigatableHomePage) {
      const membersAreaSectionURL = await this.getMembersAreaSectionURL({
        suffix: navigatableHomePage.suffix,
        memberId: memberSlug || memberId,
        onError,
      });

      location.to?.(membersAreaSectionURL);
    }

    onError?.(PublicApiError.CannotNavigateToMemberNoPublicPage);
    return Promise.resolve();
  }

  async getNavigatableRoles(onError?: Callback) {
    const pageToNavigateTo = await this.getNavigatableHomePage(onError);
    if (pageToNavigateTo) {
      const navigatableMembersRoles = pageToNavigateTo.visibleForRoles ?? [];
      return {
        navigatableMembersRoles,
        isNavigationAllowed: true,
      };
    } else {
      return {
        navigatableMembersRoles: [],
        isNavigationAllowed: false,
      };
    }
  }

  async getSectionUrl(
    {
      widgetId,
      memberId = '',
      memberSlug = '',
      tpaInnerRoute = '',
      appDefinitionId,
    }: SectionData,
    onError: Callback,
  ) {
    if (!widgetId) {
      return this.getPageUrlByAppDefId(appDefinitionId, onError);
    }

    const { location } = this.controllerConfig.wixCodeApi;
    let baseUrl = location.baseUrl;
    if (baseUrl.slice(-1) === '/') {
      baseUrl = baseUrl.slice(0, -1);
    }

    const route = await this.findRouteByWidgetId(widgetId, onError);
    const membersAreaSectionURL = await this.getMembersAreaSectionURL({
      suffix: route.path,
      memberId,
      memberSlug,
      tpaInnerRoute,
      onError,
    });

    return `${baseUrl}${membersAreaSectionURL}`;
  }

  async getMemberPagePrefix(
    data: RouterConfig,
    onSuccess: Callback,
    onError: Callback,
  ) {
    const prefix = await this.getMembersAreaPagePrefix(onError);
    return { prefix };
  }

  setNotificationCount(displayCount: number) {
    const membersLoginWidgets = getMembersLoginWidgets();

    membersLoginWidgets.forEach((widget) => {
      if (widget.navBarItems?.length) {
        widget.navBarItems = [{ ...widget.navBarItems[0], displayCount }];
      }
    });

    return Promise.resolve();
  }

  getIsMembersAreaSeoEnabled() {
    return Promise.resolve(true);
  }

  private async getPageUrlByAppDefId(
    appDefinitionId: string,
    onError?: Callback,
  ) {
    const { pages } = await this.getSiteStructure();

    const page = pages.find(
      // @ts-expect-error - missing types
      (_page) => _page.applicationId === appDefinitionId && !_page.prefix,
    );

    if (!page?.url) {
      onError?.(PublicApiError.CannotFindPageToNavigateTo);
      throw new Error(PublicApiError.CannotFindPageToNavigateTo);
    }

    return page.url;
  }

  private async getNavigatableHomePage(onError?: Callback) {
    const routes = await this.getRoutes();
    const publicRoutes = routes.filter((route) => !route.private);

    if (!publicRoutes.length) {
      return;
    }

    const navigatableHomePage = publicRoutes.find((route) => route.home);
    const prefix = await this.getMembersAreaPagePrefix(onError);

    if (navigatableHomePage) {
      return {
        prefix,
        suffix: navigatableHomePage.path,
        visibleForRoles: navigatableHomePage.vfr,
      };
    } else {
      const firstNavigatableRoute = publicRoutes[0];
      return {
        prefix,
        suffix: firstNavigatableRoute.path,
        visibleForRoles: firstNavigatableRoute.vfr,
      };
    }
  }

  private getSiteStructure() {
    const { site } = this.controllerConfig.wixCodeApi;
    return site.getSiteStructure();
  }

  private async getMembersAreaPagePrefix(onError?: Callback) {
    const { prefixes } = await this.getSiteStructure();
    const membersAreaPagePrefixData = prefixes.find(
      ({ applicationId }) => applicationId === PROFILE_PAGE_BOB_APP_DEF_ID,
    );

    if (!membersAreaPagePrefixData) {
      onError?.(PublicApiError.MissingMembersAreaPage);
      throw new Error(PublicApiError.MissingMembersAreaPage);
    }

    return membersAreaPagePrefixData.prefix;
  }

  private async getMembersAreaSectionURL({
    suffix,
    memberId,
    memberSlug,
    tpaInnerRoute,
    onError,
  }: {
    suffix: string;
    memberId?: string;
    memberSlug?: string;
    tpaInnerRoute?: string;
    onError?: Callback;
  }) {
    const userIndicator = memberSlug || memberId;
    const innerMembersAreaPath = memberId
      ? `/${userIndicator}/${suffix}`
      : `/${suffix}`;
    const membersAreaPrefix = await this.getMembersAreaPagePrefix(onError);

    const membersAreaPath = `${membersAreaPrefix}${innerMembersAreaPath}`;

    if (!tpaInnerRoute) {
      return membersAreaPath;
    }

    const innerRoute =
      tpaInnerRoute?.charAt(0) !== '/' ? `/${tpaInnerRoute}` : tpaInnerRoute;

    return `${membersAreaPath}${innerRoute}`;
  }

  private async findRouteByWidgetId(widgetId: WidgetId, onError?: Callback) {
    if (this.shouldUseNewRoutesService()) {
      try {
        return await this.routeService.getRouteConfiguration(widgetId);
      } catch (error) {
        onError?.(error);
        throw error;
      }
    }

    const routes = await this.getRoutes();
    const route = routes.find((_route) => _route.widgetId === widgetId);

    if (!route) {
      const error = `${PublicApiError.RouteNotFound} ${widgetId}`;
      onError?.(error);
      throw new Error(error);
    }

    return route;
  }

  private shouldUseNewRoutesService() {
    const { config } = this.controllerConfig;

    return !config.routes?.length;
  }
}
